import React from 'react'
import background from '@images/background.svg'
import mobileBackground from '@images/mobile_background.svg'
import tw, { styled } from 'twin.macro'
import { CloseButton } from './Form'
import { useStateContext } from '@context/stateContext'
import kathi from '@images/kathi_finall.png'
import close from '@images/close.svg'
import sticker from '@images/sticker.svg'
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'

const StyledConfirmation = styled.div`
  ${tw`flex flex-col items-center bg-white sm:h-[640px] h-[464px]`}
`

const Title = tw.p`
  sm:text-[120px] text-radler z-10 text-[70px] lg:pt-24 pt-8 font-lobster
`
const Subtitle = tw.p`
 z-10 text-[#999999] text-2xl sm:text-3xl text-center px-4 sm:px-8 font-DIN font-bold max-w-screen-xs lg:max-w-[630px] mx-auto
`

const Confirmation = () => {
  const { modalHandle } = useStateContext()
  const { isTablet } = useBreakpoint()

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <CloseButton onClick={() => modalHandle(false)}>
        <img src={close} alt="Schliessen" />
      </CloseButton>
      <img
        tw="w-full absolute top-0 left-0 object-cover h-[464px] sm:h-[640px] sm:hidden"
        src={mobileBackground}
      ></img>
      <img
        tw="w-full absolute top-0 left-0 object-cover h-[464px] hidden sm:h-[640px] sm:block"
        src={background}
      ></img>
      <StyledConfirmation>
        <Title>Danke!</Title>
        {isTablet && (
          <Subtitle>
            As Probierpackerl kimmt mit a bissl Massl boid bei Dir oh. A bissl
            dauerts, bis da Postbot kimmt.
          </Subtitle>
        )}
        {!isTablet && (
          <Subtitle>
            As Probierpackerl kimmt mit a bissl Massl boid bei Dir oh. <br />A
            bissl dauerts, bis da Postbot kimmt.
          </Subtitle>
        )}
        <img
          src={kathi}
          alt="Kathi"
          tw="absolute bottom-0 right-0 h-[42%] xxs:h-[48%] xs:h-[53%] sm:h-[55%] lg:h-[65%] "
        />
        <img
          src={sticker}
          alt="#probierfreunde"
          tw="absolute -bottom-6 sm:-bottom-8 lg:bottom-[-50px] left-0 h-[33%] xxs:h-[38%] xs:h-[40%] lg:h-auto ml-2 xs:ml-5 sm:ml-10 lg:ml-[76px] "
        />
      </StyledConfirmation>
    </motion.div>
  )
}

export default Confirmation
