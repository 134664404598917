import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { StyledLabel, StyledMessage } from './Input'

const arrow = css`
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='gray'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 10px;
  background-position: calc(100% - 5px) 13px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`

const StyledSelect = styled.select`
  ${tw`px-2.5 h-8 w-full font-roboto text-15px lg:text-18px text-gray tracking-0.25 rounded-[3px] bg-white`}
  outline: ${({ error }) =>
    error ? '2px solid #ff0000' : '2px solid #88888840'};
  ${arrow}
`

const Select = ({ value, onChange, label, options, error = '' }) => {
  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect value={value} onChange={onChange} error={error}>
        <option disabled value=""></option>
        {options.map(({ id, name }) => {
          return (
            <option key={id} value={name}>
              {name}
            </option>
          )
        })}
      </StyledSelect>
      {error && <StyledMessage error={error}>{error}</StyledMessage>}
    </>
  )
}

export default Select
