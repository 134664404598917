import React from 'react'
import tw, { styled } from 'twin.macro'

const StyledInput = styled.input`
  ${tw`px-2.5 h-8 font-roboto text-15px lg:text-18px text-gray tracking-0.25 rounded-[3px] bg-white`}
  outline: ${({ error }) =>
    error ? '2px solid #ff0000' : '2px solid #88888840'};
  &:focus {
    outline: 2px solid #006e42;
  }
  -webkit-appearance: ${({ isData }) => isData && 'none'};
`

export const Wrapper = styled.div`
  ${tw`flex w-full justify-center flex-col`}
`

export const StyledLabel = styled.label`
  ${tw`text-15px font-roboto lg:text-18px text-gray tracking-0.25 !leading-[29px]`}
`

export const StyledMessage = styled.p`
  ${tw`!text-11px !text-red-500 sm:!text-15px tracking-0.25`}
`

const Input = ({
  onChange,
  value,
  label,
  error = '',
  id,
  type = 'text',
  ...props
}) => {
  return (
    <Wrapper>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput
        id={id}
        value={value}
        onChange={onChange}
        error={error}
        type={type}
        {...props}
        isData={type === 'date'}
      />
      {error && <StyledMessage error={error}>{error}</StyledMessage>}
    </Wrapper>
  )
}

export default Input
