import { motion } from 'framer-motion'
import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { fadeIn } from '../Animations'
import Backdrop from './Backdrop'
import Confirmation from './Confirmation'
import Form from './Form'

const ModalContainer = styled(motion.div)`
  ${tw`lg:w-[1000px] h-auto sm:max-h-[95vh] bg-white w-[calc(100% - 20px)] sm:w-[90%] shadow-xl z-50 fixed`}
`

const Modal = ({ handleClose }) => {
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Backdrop onClick={handleClose}>
      <ModalContainer
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(e) => e.stopPropagation()}
      >
        {confirmation ? (
          <Confirmation />
        ) : (
          <Form onClick={() => setConfirmation(true)} />
        )}
      </ModalContainer>
    </Backdrop>
  )
}

export default Modal
