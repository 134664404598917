import React from 'react'
import tw, { styled, css } from 'twin.macro'

//variants avaliable : default | long

const primaryDark = '#006E42'
export const twCheckboxContainer = tw`relative flex flex-col mb-[40px] mx-2 items-start w-full`
export const cssCheckbox = css`
  position: relative;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  cursor: pointer;
  border-radius: 3px;
  -webkit-appearance: none;
  background-color: white;
  /* hover state */
  :not(:disabled):hover {
    border-color: ${primaryDark};
  }
  /* active state */
  :active:not(:disabled) {
    border-color: ${primaryDark};
  }
  /* focus state */
  :focus {
    outline: none;
  }
  /* input checked border color */
  :checked {
    border-color: ${primaryDark};
    background-color: ${primaryDark};
  }
  /* checkbox checked */
  :checked:before {
    content: '';
    display: block;
    width: 8px;
    height: 16px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 7px;
    margin-top: 1px;
  }
  /* disabled input */
  :disabled {
    opacity: 0.6;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
`
export const twCheckboxLabel = tw`relative font-roboto tracking-[1px] leading-[24px] text-[16px] lg:leading-[30px] lg:text-[23px] self-start ml-[14px]`
export const cssCheckboxLabel = css`
  /* unvisited link */
  a {
    text-decoration: underline;
  }
  a:link {
    color: #003899;
  }

  /* visited link */
  a:visited {
    color: purple;
  }

  /* mouse over link */
  a:hover {
    color: #0054a1;
    text-decoration: none;
  }

  /* selected link */
  a:active {
    color: #0054a1;
  }
`

const StyledCheckbox = styled.input`
  + label::after {
    content: ${({ req }) =>
      req ? "'*'" : "''"}; //adding a star to label when input is required
  }
  ${cssCheckbox}
  border: 2px solid;
  border-color: ${({ error }) => (error ? 'red' : '#88888840')};
`
const StyledLabel = styled.label`
  ${tw`font-roboto !text-11px sm:!text-15px text-gray pl-2.5`}
  a {
    ${tw`text-radler`}
    &:hover {
      filter: drop-shadow(2px 2px 0.5rem rgba(0, 110, 66, 1));
      transform: translateZ(0);
    }
  }
`
export const Checkbox = ({
  label,
  onChange,
  variant = 'default',
  id,
  error,
  ...props
}) => {
  return (
    <div tw="relative flex flex-col items-center">
      <div tw="flex items-center">
        <StyledCheckbox
          type="checkbox"
          onChange={onChange}
          error={error}
          {...props}
          id={id}
        />
        <StyledLabel htmlFor={id}>
          Ich bestätige, dass ich{' '}
          <a href="/teilnahmebedingungen" target="_blank">
            die Teilnahmebedingungen
          </a>{' '}
          und{' '}
          <a href="/datenschutz" target="_blank">
            die Hinweise zum Datenschutz
          </a>{' '}
          gelesen habe.
        </StyledLabel>
      </div>
    </div>
  )
}
