import { motion } from 'framer-motion'
import React from 'react'
import tw, { styled } from 'twin.macro'

const StyledBackdrop = styled(motion.div)`
  ${tw`fixed top-0 left-0 h-full w-full bg-[#f0f0f029] flex items-center justify-center z-[1000] pointer-events-auto`}
`

const Backdrop = ({ children, onClick }) => {
  return (
    <StyledBackdrop
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.2,
          ease: 'easeOut',
        },
      }}
      onClick={onClick}
    >
      {children}
    </StyledBackdrop>
  )
}

export default Backdrop
