import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import close from '@images/close.svg'
import box from '@images/Pack.png'
import Select from '../common/Select'
import Input, { StyledMessage } from '../common/Input'
import { motion } from 'framer-motion'
import { Checkbox } from '../common/Checkbox'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const options = [
  { id: 'Herr', name: 'Herr' },
  { id: 'Frau', name: 'Frau' },
  { id: 'Divers', name: 'Divers' },
]

const StyledForm = styled.div`
  ${tw`flex items-center overflow-y-auto h-auto sm:h-auto sm:max-h-[90vh]`}
  p {
    ${tw`font-roboto text-15px lg:text-18px text-gray tracking-0.25`}
  }
`

const Title = styled.div`
  ${tw`px-2.5 pt-5 lg:pt-12 w-[80%] sm:text-center sm:w-full pb-2.5 md:pb-9 !font-DIN`}
  p {
    ${tw`font-DIN leading-[18px] sm:!text-30px font-bold`}
  }
`

export const CloseButton = styled.button`
  ${tw`absolute top-2.5 right-2.5 w-9 h-9 rounded-full bg-darkGray flex items-center justify-center lg:top-5 lg:right-5 z-10`}
`
const InputsWrapper = styled.div`
  ${tw`grid grid-cols-6 gap-x-2.5 px-2 sm:px-5 lg:px-[50px] pb-5 sm:pb-12`}
`

const Button = styled.button`
  ${tw`w-[117px] h-9 rounded-[3px] font-roboto !font-normal text-15px text-white tracking-0.25 sm:text-18px sm:h-[45px] sm:w-[140px]`}
  background: #006E42 linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
`

const LeftColumn = tw.div`
  flex-1 h-auto max-h-[95vh]
`

const RightColumn = tw.div`
  md:flex-[0.7] flex-1 hidden md:flex justify-center items-center lg:flex-1 h-auto max-h-[90vh]
`

const Cell = styled.div`
  padding-bottom: ${({ error }) => (error ? '0px' : '8px')};
`
const regExp = /\b\d{5}\b/

const link = 'https://www.erdinger-brauhaus.de/api/save.php'

const Form = ({ onClick }) => {
  const { modalHandle } = useStateContext()
  const [error, setError] = useState('')

  const getFormData = (object) => {
    const formData = new FormData()
    Object.keys(object).forEach((key) => formData.append(key, object[key]))
    return formData
  }

  const onSubmit = () => {
    const token =
      '03AGdBq27zt44OfQa3wv-q8giahsPd5QGIo-MJ8AEmqLItQb8BVZpFMMKhvL6LEyVndVfddDqNTiknyUIVFq3JBTT0cmnBdqTIVYX9oX18MSTbC1xxqRggr0t97BtfM2ZHKVDTtE0f7uYT57FshQ3yrofBlhxegb7GWZq1ysUH1PpRvf2BH623HqMUqjuPNQ92ZTNIrER6VH2gRqroEQad-oj6XsdiKS1t_k3Q0ZSLgRCQ3txqdGlXyppW9osLkL4YtaR3fMqnwPLZry146SYKi1fF0BlNGHfddxeS4m0Tqzyk1PZt3SVCWgPqt3EZDuPItgCAp99WBOtKKVsMinoVxWTgVndQYNnYLWSdoj_caX2qewett2i4kMaz5AWwUEvxOFXFWCNhFgBNIb0nwpGZItblEA0Bkq-n5tOagg-Oe3zN-cJJP3_xBG8Rdl8t_GVsjiimL6ZP-0-PuqcAJq5Y1yMSzTRcOB9ZQA'
    const formValues = {
      token,
      anrede: values.gender,
      vorname: values.firstName,
      nachname: values.lastName,
      email: values.email,
      geburtsdatum: values.dateOfBirth,
      strasse: values.street,
      hausnummer: values.number,
      plz: values.zipCode,
      ort: values.ort,
      land: 'Deutschland',
    }

    fetch(link, {
      method: 'POST',
      body: getFormData(formValues),
    })
      .then((res) => {
        return res.text()
      })
      .then((body) => {
        if (body === 'ok') {
          onClick()
        }
        if (body !== 'ok') {
          setError(body)
        }
      })
      .catch((err) => {
        setError(err)
      })
  }

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required('Pflichtfeld'),
    firstName: Yup.string().required('Pflichtfeld'),
    lastName: Yup.string().required('Pflichtfeld'),
    dateOfBirth: Yup.date()
      .max(
        new Date(Date.now() - (504526816000 + 432000000)),
        'Du musst mindestens 16 Jahre alt sein'
      )
      .required('Pflichtfeld'),
    email: Yup.string().email('Ungültige E-Mail').required('Pflichtfeld'),
    street: Yup.string().required('Pflichtfeld'),
    number: Yup.string().required('Pflichtfeld'),
    zipCode: Yup.string()
      .matches(regExp, {
        message: 'Es müssen genau 5 Zahlen sein',
        excludeEmptyString: true,
      })
      .required('Pflichtfeld'),
    ort: Yup.string().required('Pflichtfeld'),
    checked: Yup.boolean().oneOf([true], 'Pflichtfeld').required(),
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      gender: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      street: '',
      number: '',
      zipCode: '',
      ort: '',
      checked: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      'https://www.google.com/recaptcha/api.js?render=6LffHfYUAAAAAKkqBTm-2zoMcQsxrixh9Td7FfKw'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      tw="sm:h-auto sm:max-h-[95vh] flex items-center"
    >
      <CloseButton onClick={() => modalHandle(false)}>
        <img src={close} alt="Schliessen" />
      </CloseButton>
      <StyledForm>
        <LeftColumn>
          <Title>
            <p>
              Schreib Dei Adress da do nei
              <br />
              und gwinn Dei Probierpackerl
            </p>
          </Title>
          <InputsWrapper>
            <Cell tw="col-span-2" error={errors.gender || errors.firstName}>
              <Select
                value={values.gender}
                onChange={handleChange('gender')}
                label="Anrede"
                options={options}
                placeholder="Anrede"
                error={errors.gender}
              />
            </Cell>
            <Cell tw="col-span-4" error={errors.gender || errors.firstName}>
              <Input
                value={values.firstName}
                onChange={handleChange('firstName')}
                label="Vorname"
                error={errors.firstName}
                id="firstName"
              />
            </Cell>
            <Cell tw="col-span-6" error={errors.lastName}>
              <Input
                value={values.lastName}
                onChange={handleChange('lastName')}
                label="Name"
                error={errors.lastName}
                id="lastName"
              />
            </Cell>
            <Cell tw="col-span-3" error={errors.dateOfBirth || errors.email}>
              <Input
                value={values.dateOfBirth}
                onChange={handleChange('dateOfBirth')}
                label="Geburtsdatum"
                type="date"
                error={errors.dateOfBirth}
                id="dateOfBirth"
              />
            </Cell>
            <Cell tw="col-span-3" error={errors.dateOfBirth || errors.email}>
              <Input
                value={values.email}
                onChange={handleChange('email')}
                label="E-Mail"
                error={errors.email}
                id="email"
              />
            </Cell>
            <Cell tw="col-span-4" error={errors.street || errors.number}>
              <Input
                value={values.street}
                onChange={handleChange('street')}
                label="Straße"
                error={errors.street}
                id="street"
                x
              />
            </Cell>
            <Cell tw="col-span-2" error={errors.street || errors.number}>
              <Input
                value={values.number}
                onChange={handleChange('number')}
                label="Hausnummer"
                error={errors.number}
                id="number"
              />
            </Cell>
            <Cell
              tw="col-span-2 sm:col-span-2"
              error={errors.zipCode || errors.ort}
            >
              <Input
                value={values.zipCode}
                onChange={handleChange('zipCode')}
                label="PLZ"
                error={errors.zipCode}
                id="zipCode"
              />
            </Cell>
            <Cell
              tw="col-span-2 sm:col-span-2"
              error={errors.ort || errors.ort}
            >
              <Input
                value={values.ort}
                onChange={handleChange('ort')}
                label="Ort"
                error={errors.ort}
                id="ort"
              />
            </Cell>
            <Cell
              tw="col-span-2 sm:col-span-2"
              error={errors.ort || errors.ort}
            >
              <Input
                value={'Deutschland'}
                onChange={(e) => {}}
                label="Land"
                disabled
                id="land"
              />
            </Cell>
            <div tw="col-span-6 grid grid-cols-6 pt-2.5 sm:pt-0">
              <div tw="col-span-4 items-center justify-center sm:col-span-6 max-h-16">
                <Checkbox
                  label={
                    'Ich bestätige, dass ich die Teilnahmebedingungen und die Hinweise zum Datenschutz gelesen habe.'
                  }
                  value={values.checked}
                  onChange={handleChange('checked')}
                  error={errors.checked}
                  id="statute"
                />
              </div>
              <div tw="col-span-2 flex items-center justify-center sm:hidden">
                <Button onClick={handleSubmit}>abschicken</Button>
              </div>
            </div>
            <div tw="col-span-6 justify-center hidden sm:flex sm:m-2.5">
              <Button onClick={handleSubmit}>abschicken</Button>
            </div>
            <div tw="col-span-6">
              {(errors.checked || error) && (
                <StyledMessage error={errors.checked}>
                  {errors.checked || error}
                </StyledMessage>
              )}
            </div>
          </InputsWrapper>
        </LeftColumn>
        <RightColumn>
          <img src={box} alt="ERDINGER Brauhaus" />
        </RightColumn>
      </StyledForm>
    </motion.div>
  )
}

export default Form
