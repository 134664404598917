import React from 'react'
import { motion } from 'framer-motion'

const hidden = {
  scale: 0.9,
  opacity: 0,
}
const exit = {
  scale: 1.05,
  opacity: 0,
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.3,
  },
}

export const fadeInOut = {
  hidden,
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.3,
      delay: 0.25,
    },
  },
  exit,
}
export const fadeInOutv2 = {
  hidden,
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.3,
      delay: 0.55,
    },
  },
  exit,
}

export const fadeIn = {
  hidden: {
    y: '200vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'spring',
      damping: 50,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
}

export const dropIn = {
  hidden: {
    y: '250%',
    opacity: 0.5,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.25,
      type: 'spring',
      bounce: 0.6,
    },
  },
  exit: {
    y: '250%',
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
}

export const firstScreenDelay = 0.25
export const secondScreenDelay = 0.55

//home screen motion, fadeInOut for the 1st screen, fadeInOut2 for 2nd
export const MotionFadeZoom = ({
  children,
  initial = hidden,
  className,
  delay = firstScreenDelay,
  ...props
}) => (
  <motion.div
    initial={initial}
    animate={{
      scale: 1,
      opacity: 1,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.3,
        delay,
      },
    }}
    exit={exit}
    className={className}
    {...props}
  >
    {children}
  </motion.div>
)
